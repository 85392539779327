import React, { useState } from "react";
import login_logo from "../Images/login_logo.png";
import Navbar from "../Components/Navbar";
import { useEffect } from "react";
import { message as MESSAGE } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { IoEyeSharp } from "react-icons/io5";
import pageRoutes from "../routes";
import SubNavbar from "./SubNavbar";
export const configJSON = require("../Components/Config");

function Setting() {
  const navigate = useNavigate();

  const [shares, setShares] = useState("");
  const [isEdit, setIsEdit] = useState(true);
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [currentPass, setCurrentPass] = useState();
  const [newPass, setNewPass] = useState();
  const [confirmPass, setConfirmPass] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [demo, setDemo] = useState([]);
  const [isEye1, setIsEye1] = useState(false);
  const [isEye2, setIsEye2] = useState(false);
  const [isEye3, setIsEye3] = useState(false);
  const [type1, setType1] = useState("password");
  const [type2, setType2] = useState("password");
  const [type3, setType3] = useState("password");

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token == null) {
      navigate(pageRoutes?.login);
    } else {
      getData();
    }
  }, []);

  const handleEye1 = () => {
    var chtype = type1 == "text" ? "password" : "text";
    setType1(chtype);
    setIsEye1(!isEye1);
  };
  const handleEye2 = () => {
    var chtype = type2 == "text" ? "password" : "text";
    setType2(chtype);
    setIsEye2(!isEye2);
  };
  const handleEye3 = () => {
    var chtype = type3 == "text" ? "password" : "text";
    setType3(chtype);
    setIsEye3(!isEye3);
  };

  const ChangePass = () => {
    if (newPass && confirmPass && currentPass) {
      setIsLoader(true);
      if (newPass == confirmPass) {
        if (demo?.password == currentPass) {
          setIsLoader(true);
          const data = {
            user_id: `${demo.id}`,
            password: confirmPass,
            current_password: demo.password,
          };
          axios({
            url: configJSON.baseUrl + configJSON.password_change,
            method: "post",
            data: data,
          })
            .then((res) => {
              setConfirmPass("");
              setCurrentPass("");
              setNewPass("");
              MESSAGE.success(res?.data?.message);
              setIsLoader(false);
            })
            .catch((error) => {
              console.log(error);
              setIsLoader(false);
            });
        } else {
          setIsLoader(false);
          MESSAGE.error("Current password does not matched");
        }
      } else {
        setIsLoader(false);
        MESSAGE.error("New password and Confirm password doesn't match");
      }
    } else {
      setIsLoader(false);
      MESSAGE.error("Field can not be empty!");
    }
  };

  const getData = () => {
    axios({
      url: configJSON.baseUrl + configJSON.get_admin_profile,
      method: "get",
    })
      .then((res) => {
        setDemo(res.data.data);
        setFullName(res.data.data.full_name);
        setEmail(res.data.data.email);
        setShares(res.data.data.share_value);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const UpdateData = (e) => {
    e.preventDefault();
    const data = {
      user_id: `${demo.id}`,
      full_name: fullName,
      email: email,
      share_value: shares,
    };
    if (fullName && email && shares) {
      axios({
        url: configJSON.baseUrl + configJSON.new_update_admin,
        method: "post",
        data: data,
      })
        .then((res) => {
          MESSAGE.success(res?.data?.message);
          setIsEdit(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      MESSAGE.error("Field can not be empty!");
    }
  };

  return (
    <>
      <section className="ct_padd_in_100">
        <Navbar data="settings" />

        <div className="container-fluid">
          {/* <SubNavbar /> */}
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-10">
              <form action="" className="mb-5">
                {isEdit && (
                  <div className="ct_profile_main mt-0">
                    <h4>Profile</h4>
                    <div className="d-flex justify-content-between align-items-center flex-wrap mt-3 pb-3 gap-3">
                      <div className="d-flex align-items-center flex-wrap gap-3 ">
                        <div className="ct_profile_img">
                          <img src={login_logo} alt="img/login_logo.png" />
                        </div>
                        <div className="d-flex align-items-center gap-4">
                          <div className="ct_user_info_name">
                            <h5>{fullName}</h5>
                            <small>{email}</small>
                          </div>
                        </div>
                      </div>
                      <div className="ct_active_link">
                        <button
                          className="ct_outline_btn"
                          onClick={() => setIsEdit(false)}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="ct_profile_input_main mt-4">
                      <div className="ct_profile_input mb-4">
                        <p className="mb-1">Full Name</p>
                        <h5 className="ct_fw_500 ct_font_20">{fullName}</h5>
                      </div>
                      <div className="ct_profile_input mb-4">
                        <p className="mb-1">Email</p>
                        <h5 className="ct_fw_500 ct_font_20">{email}</h5>
                      </div>
                      <div className="ct_profile_input">
                        <p className="mb-1">Shares</p>
                        <h5 className="ct_fw_500 ct_font_20">{shares}</h5>
                      </div>
                    </div>
                  </div>
                )}
                {isEdit == false && (
                  <div className="ct_profile_main mt-0">
                    <h4>Profile</h4>
                    <div className="d-flex justify-content-between align-items-center flex-wrap mt-3 pb-3 gap-3">
                      {isLoader == true && (
                        <div className="loader">
                          <svg
                            className="car"
                            width="102"
                            height="40"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              transform="translate(2 1)"
                              stroke="#002742"
                              fill="none"
                              fill-rule="evenodd"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                className="car__body"
                                d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01"
                                stroke-width="3"
                              />
                              <ellipse
                                className="car__wheel--left"
                                stroke-width="3.2"
                                fill="#FFF"
                                cx="83.493"
                                cy="30.25"
                                rx="6.922"
                                ry="6.808"
                              />
                              <ellipse
                                className="car__wheel--right"
                                stroke-width="3.2"
                                fill="#FFF"
                                cx="46.511"
                                cy="30.25"
                                rx="6.922"
                                ry="6.808"
                              />
                              <path
                                className="car__line car__line--top"
                                d="M22.5 16.5H2.475"
                                stroke-width="3"
                              />
                              <path
                                className="car__line car__line--middle"
                                d="M20.5 23.5H.4755"
                                stroke-width="3"
                              />
                              <path
                                className="car__line car__line--bottom"
                                d="M25.5 9.5h-19"
                                stroke-width="3"
                              />
                            </g>
                          </svg>
                        </div>
                      )}
                      {isLoader == false && (
                        <div className="d-flex align-items-center flex-wrap gap-3 ">
                          <div className="ct_profile_img">
                            <img src={login_logo} alt="img/login_logo.png" />
                          </div>
                          <div className="d-flex align-items-center gap-4">
                            <div className="ct_user_info_name">
                              <h5>{fullName}</h5>
                              <small>{email}</small>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="ct_active_link">
                        <button
                          type="button"
                          className="ct_outline_btn"
                          onClick={(e) => UpdateData(e)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="ct_profile_input_main mt-4">
                      <div className="form-group mb-4">
                        <div className="position-relative">
                          <label for="" className="mb-2">
                            Full Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-4">
                        <div className="position-relative">
                          <label for="" className="mb-2">
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <div className="position-relative">
                          <label for="" className="mb-2">
                            Shares
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Shares"
                            value={shares}
                            onChange={(e) => setShares(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isLoader == true && (
                  <div className="loader">
                    <svg
                      className="car"
                      width="102"
                      height="40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        transform="translate(2 1)"
                        stroke="#002742"
                        fill="none"
                        fill-rule="evenodd"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          className="car__body"
                          d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01"
                          stroke-width="3"
                        />
                        <ellipse
                          className="car__wheel--left"
                          stroke-width="3.2"
                          fill="#FFF"
                          cx="83.493"
                          cy="30.25"
                          rx="6.922"
                          ry="6.808"
                        />
                        <ellipse
                          className="car__wheel--right"
                          stroke-width="3.2"
                          fill="#FFF"
                          cx="46.511"
                          cy="30.25"
                          rx="6.922"
                          ry="6.808"
                        />
                        <path
                          className="car__line car__line--top"
                          d="M22.5 16.5H2.475"
                          stroke-width="3"
                        />
                        <path
                          className="car__line car__line--middle"
                          d="M20.5 23.5H.4755"
                          stroke-width="3"
                        />
                        <path
                          className="car__line car__line--bottom"
                          d="M25.5 9.5h-19"
                          stroke-width="3"
                        />
                      </g>
                    </svg>
                  </div>
                )}
                {isLoader == false && (
                  <div className="ct_profile_main mt-5">
                    <h4>Change Password</h4>
                    <div className="form-group mb-4">
                      <label for="" className="mb-2">
                        Current Password
                      </label>
                      <div className="position-relative">
                        <input
                          type={type1}
                          className="form-control"
                          value={currentPass}
                          onChange={(e) => setCurrentPass(e.target.value)}
                        />
                        {isEye1 == false && (
                          <BsFillEyeSlashFill
                            className="ct_hide_eye"
                            onClick={handleEye1}
                          />
                        )}
                        {isEye1 && (
                          <IoEyeSharp
                            className="ct_hide_eye"
                            onClick={handleEye1}
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label for="" className="mb-2">
                        New Password
                      </label>
                      <div className="position-relative">
                        <input
                          type={type2}
                          value={newPass}
                          onChange={(e) => setNewPass(e.target.value)}
                          className="form-control"
                        />
                        {isEye2 == false && (
                          <BsFillEyeSlashFill
                            className="ct_hide_eye"
                            onClick={handleEye2}
                          />
                        )}
                        {isEye2 && (
                          <IoEyeSharp
                            className="ct_hide_eye"
                            onClick={handleEye2}
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="" className="mb-2">
                        Confirm Password
                      </label>
                      <div className="position-relative">
                        <input
                          type={type3}
                          value={confirmPass}
                          onChange={(e) => setConfirmPass(e.target.value)}
                          className="form-control"
                        />
                        {isEye3 == false && (
                          <BsFillEyeSlashFill
                            className="ct_hide_eye"
                            onClick={handleEye3}
                          />
                        )}
                        {isEye3 && (
                          <IoEyeSharp
                            className="ct_hide_eye"
                            onClick={handleEye3}
                          />
                        )}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="ct_custome_btn mt-4"
                      onClick={ChangePass}
                    >
                      Change Password
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Setting;
