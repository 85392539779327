import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import pageRoutes from "../routes";
import SubNavbar from "./SubNavbar";
import { useDispatch } from "react-redux";
import { fetchReassignRequest } from "../redux/action/settingAction";

function ReAssign() {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token == null) {
      navigate(pageRoutes?.login);
    } else {
      // dispatch(fetchReassignRequest())
    }
  }, []);

  return (
    <>
      <section className="ct_padd_in_100">
        <Navbar />

        <div className="container-fluid">
          <SubNavbar />

          <div className="ct_location_table table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>User Name</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Ride Detail</th>
                  <th>Assign Driver</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <div className="ct_user_img_circle  d-flex align-items-center gap-2">
                      <img src="img/Group_1634.png" alt="" />
                      <h6 className="mb-0">John Doe</h6>
                    </div>
                  </td>
                  <td>Nov 28th 2024</td>
                  <td>4:36 PM</td>
                  <td>Aberaman to Abergele</td>
                  <td>
                    <select className="form-control">
                      <option>John Doe</option>
                      <option>Adney</option>
                      <option>Aldo</option>
                      <option>Angel</option>
                      <option>Anson</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <div className="ct_user_img_circle  d-flex align-items-center gap-2">
                      <img src="img/Group_1634.png" alt="" />
                      <h6 className="mb-0">John Doe</h6>
                    </div>
                  </td>
                  <td>Nov 28th 2024</td>
                  <td>4:36 PM</td>
                  <td>Aberaman to Abergele</td>
                  <td>
                    <select className="form-control">
                      <option>John Doe</option>
                      <option>Adney</option>
                      <option>Aldo</option>
                      <option>Angel</option>
                      <option>Anson</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <div className="ct_user_img_circle  d-flex align-items-center gap-2">
                      <img src="img/Group_1634.png" alt="" />
                      <h6 className="mb-0">John Doe</h6>
                    </div>
                  </td>
                  <td>Nov 28th 2024</td>
                  <td>4:36 PM</td>
                  <td>Aberaman to Abergele</td>
                  <td>
                    <select className="form-control">
                      <option>John Doe</option>
                      <option>Adney</option>
                      <option>Aldo</option>
                      <option>Angel</option>
                      <option>Anson</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default ReAssign;
