import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { message, message as MESSAGE } from "antd";
import axios from 'axios';
import pageRoutes from '../../routes';

export const configJSON = require("../Config");

const AllBooking = () => {
    const navigate = useNavigate()
    const [allbookingData, setAllbookingData] = useState([]);
    const [activeRides, setActiveRides] = useState([]);
    const [userData, setUserData] = useState([]);
    const [closediv, setCloseDiv] = useState([]);

    useEffect(() => {
        getProfileData();
        const val = JSON.parse(localStorage.getItem("user_details"))
        const data = {
            phone_no: val
        }
        axios({
            method: 'post',
            url: configJSON.webBaseUrl + configJSON.webGetAllBookingEndPointURL,
            data: data
        })
            .then((res) => {
                console.log({ res })
                if (res?.data?.success == true) {
                    for (var i = 0; i < res?.data?.data?.length; i++) {
                        if (res?.data?.data[i]?.driver_status == 0 || res?.data?.data[i]?.driver_status == 1 && res?.data?.data[i]?.Ride_status != 2) {
                            activeRides?.push(res?.data?.data[i])
                        }
                    }
                    setActiveRides(activeRides => activeRides?.filter((item) => item));
                    for (var i = 0; i < res?.data?.data?.length; i++) {
                        if (res?.data?.data[i]?.driver_status == 2 || res?.data?.data[i]?.Ride_status == 2) {
                            allbookingData?.push(res?.data?.data[i])
                        }
                    }
                    setAllbookingData(allbookingData => allbookingData?.filter((item) => item));
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const onhandleCloseDiv = (val) => {
        if (!closediv?.includes(val?.id)) {
            closediv?.push(val?.id);
            setCloseDiv(closediv => closediv?.filter((items) => items))
        } else {
            setCloseDiv(closediv => closediv?.filter((items) => items !== val?.id))
        }
    }

    const getProfileData = () => {
        const token = JSON.parse(localStorage.getItem("token"));
        axios({
            method: "post",
            url: configJSON?.webBaseUrl + configJSON.webGetUserDataEndPointURL,
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((res) => {
                console.log({ res })
                setUserData(res?.data?.user_info);
                localStorage.setItem("user_image", JSON.stringify(res?.data?.user_info[0]?.profile_image))
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div>
            <header>
                <div class="ct_navigation">
                    <a onClick={() => navigate(pageRoutes?.allBooking)} class="ct_logo">
                        <img src="img/logo.png" alt="img" />
                    </a>
                    <div className="ct_profile_drop" onClick={() => navigate(pageRoutes?.userProfile)}>
                        <img src={userData[0]?.profile_image != 0 ? userData[0]?.profile_image : "img/user1.png"} alt="" className="ct_profile_logo" />
                    </div>
                </div>
            </header>
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-md-10 mx-auto">
                            <div class="ct_reutn_booking_main ct_center_positioin w-50">
                                <div class="position-relative">
                                    <h4 class="ct_head_h4">All Booking</h4>
                                </div>
                                <div class="ct_booking_tabs_main">
                                    <ul class="nav nav-pills">
                                        <li class="nav-item">
                                            <a class="nav-link active" data-bs-toggle="pill" href="#home">Active Now</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" data-bs-toggle="pill" href="#menu1">History</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <div class="tab-pane container active" id="home">
                                            <div class="ct_booking_card_main">
                                                {activeRides?.length !== 0 ?
                                                    activeRides?.map((item) => (
                                                        item?.driver_status == 0 || 1 &&
                                                        console.log({ item }),
                                                        <div class="ct_booking_card mb-3">
                                                            <div class="ct_border_bottom">
                                                                <div class="d-flex justify-content-between ct_flex_responsive">
                                                                    <div class="ct_booking_user">
                                                                        <img src={item?.driver_profile_image ? item?.driver_profile_image : "img/user1.png"} alt="" />
                                                                        <div>
                                                                            <h4 class="mb-1">{item?.driver_info[0]?.name}</h4>
                                                                            <p class="mb-0">{item?.driver_info[0]?.car_model}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <a href="javascript:void(0)" class="ct_text_yellow ct_decoration_none ct_fw_600">
                                                                            {item?.driver_status == 0 ? "Pending" : "Active"}</a>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex align-items-center justify-content-between mt-2">
                                                                    <p class="mb-0 ct_fw_600">{item?.dateOfBooking}</p>
                                                                    <p class="mb-0 ct_fw_600">{item?.time}</p>
                                                                </div>
                                                            </div>
                                                            <div class={!closediv?.includes(item?.id) ? "ct_collape_div" : "ct_collape_open"}>
                                                                <div class="mt-3">
                                                                    <div class="d-flex align-items-center  justify-content-between">
                                                                        <p class="mb-0 d-flex align-items-center gap-2"><img src="img/location.png" alt="" />{item?.distance}</p>
                                                                        <p class="mb-0 d-flex align-items-center gap-2"><img src="img/clock.png" alt="" />{item?.duration}</p>
                                                                    </div>
                                                                    <div class="d-flex align-items-center justify-content-between mt-3 ">
                                                                        <p class="mb-0 ct_fw_600 ct_fs_14">Date & Time</p>
                                                                        <p class="mb-0 ct_fw_600 ct_fs_14">{item?.dateOfBooking} | {item?.time}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="ct_location_div pt-4">
                                                                    <div class="d-flex align-items-center justify-content-between mb-4">
                                                                        <div class="ct_location_cnt">
                                                                            <h4 class="ct_fw_600">Current Location</h4>
                                                                            <p class="mb-0 d-flex align-items-center gap-2 ct_gret_text ct_fw_600"><img src="img/map_icon.png" alt="" />{item?.start_2}</p>
                                                                        </div>
                                                                        <div>
                                                                            <img src="img/Vector.png" alt="img/Vector.png" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex align-items-center justify-content-between mb-4">
                                                                        <div class="ct_location_cnt">
                                                                            <h4 class="ct_fw_600">Soft Bank Buildings</h4>
                                                                            <p class="mb-0 d-flex align-items-center gap-2 ct_gret_text ct_fw_600"><img src="img/map_icon.png" alt="" />{item?.destination_1}</p>
                                                                        </div>
                                                                        <div>
                                                                            <img src="img/Vector-red.png" alt="img/Vector-red.png" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <a href={`tel:${item?.driver_info[0]?.phone_no}`} class="ct_custom_btn d-flex align-items-center gap-2 w-auto" style={{ width: "fitContent" }}><img src="img/headphone.png" alt="img/headphone.png" />Contact us</a>
                                                            </div>
                                                            <div onClick={() => onhandleCloseDiv(item)} class="ct_up_down_arrow text-center pt-3">
                                                                <i class={closediv?.includes(item?.id) ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></i>
                                                            </div>
                                                        </div>
                                                    )) :
                                                    <div className="text-center pt-5">
                                                        No booking found !!!
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div class="tab-pane container fade" id="menu1">
                                            <div class="ct_booking_card_main">
                                                {allbookingData?.length !== 0 ?
                                                    allbookingData?.map((item) => (
                                                        item?.driver_status == 2 || 3 &&
                                                        console.log({ item }),
                                                        <div class="ct_booking_card mb-3">
                                                            <div class="ct_border_bottom">
                                                                <div class="d-flex justify-content-between ct_flex_responsive">
                                                                    <div class="ct_booking_user">
                                                                        <img src={item?.driver_profile_image ? item?.driver_profile_image : "img/user1.png"} alt="" />
                                                                        <div>
                                                                            <h4 class="mb-1">{item?.driver_info[0]?.name}</h4>
                                                                            <p class="mb-0">{item?.driver_info[0]?.car_model}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <a href="javascript:void(0)" class="ct_text_yellow ct_decoration_none ct_fw_600">
                                                                            {item?.driver_status == 2 && "Cancelled"}{item?.Ride_status == 2 && "Completed"}</a>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex align-items-center justify-content-between mt-2">
                                                                    <p class="mb-0 ct_fw_600">{item?.dateOfBooking}</p>
                                                                    <p class="mb-0 ct_fw_600">{item?.time}</p>
                                                                </div>
                                                            </div>
                                                            <div class={!closediv?.includes(item?.id) ? "ct_collape_div" : "ct_collape_open"}>
                                                                <div class="mt-3">
                                                                    <div class="d-flex align-items-center  justify-content-between">
                                                                        <p class="mb-0 d-flex align-items-center gap-2"><img src="img/location.png" alt="" />{item?.distance}</p>
                                                                        <p class="mb-0 d-flex align-items-center gap-2"><img src="img/clock.png" alt="" />{item?.duration}</p>
                                                                    </div>
                                                                    <div class="d-flex align-items-center justify-content-between mt-3 ">
                                                                        <p class="mb-0 ct_fw_600 ct_fs_14">Date & Time</p>
                                                                        <p class="mb-0 ct_fw_600 ct_fs_14">{item?.dateOfBooking} | {item?.time}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="ct_location_div pt-4">
                                                                    <div class="d-flex align-items-center justify-content-between mb-4">
                                                                        <div class="ct_location_cnt">
                                                                            <h4 class="ct_fw_600">Current Location</h4>
                                                                            <p class="mb-0 d-flex align-items-center gap-2 ct_gret_text ct_fw_600"><img src="img/map_icon.png" alt="" />{item?.destination}</p>
                                                                        </div>
                                                                        <div>
                                                                            <img src="img/Vector.png" alt="img/Vector.png" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex align-items-center justify-content-between mb-4">
                                                                        <div class="ct_location_cnt">
                                                                            <h4 class="ct_fw_600">Soft Bank Buildings</h4>
                                                                            <p class="mb-0 d-flex align-items-center gap-2 ct_gret_text ct_fw_600"><img src="img/map_icon.png" alt="" />{item?.destination_1}</p>
                                                                        </div>
                                                                        <div>
                                                                            <img src="img/Vector-red.png" alt="img/Vector-red.png" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <a href={`tel:${item?.driver_info[0]?.phone_no}`} class="ct_custom_btn d-flex align-items-center gap-2 w-auto" style={{ width: "fitContent" }}><img src="img/headphone.png" alt="img/headphone.png" />Contact us</a>
                                                            </div>
                                                            <div onClick={() => onhandleCloseDiv(item)} class="ct_up_down_arrow text-center pt-3">
                                                                <i class={closediv?.includes(item?.id) ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></i>
                                                            </div>
                                                        </div>
                                                    )) :
                                                    <div className="text-center pt-5">
                                                        No booking found !!!
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </section>
        </div >
    )
}

export default AllBooking
