import React from "react";
import { HashLink } from "react-router-hash-link";
const Header = () => {
  return (
    <header>
      <div className="container">
        <div className="col-md-12">
          <div className="ct_navbar">
            <div className="ct_logo">
              <img src="img/logo.svg" alt="" />
            </div>

            <div className="ct_menu_list">
              <i className="fa-solid fa-bars-staggered ct_toggle_bar"></i>

              <ul>
                <li>
                  <HashLink smooth to="/#home">
                    Home
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to="/#about">
                    About Us
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to="/#service">
                    Services
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to="/#download">
                    Download App
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to="/#howtouse">
                    How to Use
                  </HashLink>
                </li>

                <i className="fa-solid fa-xmark ct_close_bar"></i>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
