import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import settingReducer from "./reducers/settingReducer";

const store = configureStore({
  reducer: {
    authReducer,
    settingReducer,
  },
});

export default store;
