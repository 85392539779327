import { API_REQUEST } from ".";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllReassignRequestAPI } from "../../Components/Config";

// fetch-reassign-request
export const fetchReassignRequest = createAsyncThunk("fetch-reassign-request", async () => {
    try {
      const response = await API_REQUEST({
        url: getAllReassignRequestAPI,
        method: "GET",
      });
      return response;
    } catch (error) {}
  });
  