import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import pageRoutes from "../routes";

const SubNavbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="cb_setting_tab">
          <ul>
            <li>
              <a
                href="javascript:void(0)"
                className={pathname == pageRoutes?.reAssign ? "active" : ""}
                onClick={() => navigate(pageRoutes?.reAssign)}
              >
                Reassign
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className={pathname == pageRoutes?.complaint ? "active" : ""}
                onClick={() => navigate(pageRoutes.complaint)}
              >
                Complaint
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className={
                  pathname == pageRoutes?.emergencyNotification ? "active" : ""
                }
                onClick={() => navigate(pageRoutes.emergencyNotification)}
              >
                Emergency Notification
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className={pathname == pageRoutes?.settings ? "active" : ""}
                onClick={() => navigate(pageRoutes.settings)}
              >
                My Profile
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SubNavbar;
