import React from 'react'
import { HashLink } from 'react-router-hash-link'

const Footer = () => {
  return (
    <footer className="pb-0">
    <div className="container">
      <div className="row">
        <div className="col-md-3 mb-4  col-sm-6">
          <div className="ct_footer_logo">
            <img src="img/logo.svg" alt="" />

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Eligendi quasi velit esse.
            </p>

            <div className="ct_social_media_links mt-4">
              <a href="#">
                <i className="fa-brands fa-facebook-f"></i>
              </a>

              <a href="#">
                <i className="fa-brands fa-square-instagram"></i>
              </a>

              <a href="#">
                <i className="fa-brands fa-x-twitter"></i>
              </a>

              <a href="#">
                <i className="fa-brands fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="col-md-3 mb-4  col-sm-6">
          <div className="ct_footer_link">
            <h4>Quick Links</h4>

            <ul>
            <li>
                  <HashLink smooth to="/#home">
                    Home
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to="/#about">
                    About Us
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to="/#service">
                    Services
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to="/#download">
                    Download App
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to="/#Contact">
                    Contact Us
                  </HashLink>
                </li>
            </ul>
          </div>
        </div>

        <div className="col-md-3 mb-4  col-sm-6">
          <div className="ct_footer_link">
            <h4>Contact Details</h4>

            <ul>
              <li>
                <h6>Phone Number</h6>

                <div className="ct_footer_info">
                  <i className="fa-solid fa-phone"></i>

                  <a href="tel:1234567890">1234567890</a>
                </div>
              </li>

              <li>
                <h6>Email Address</h6>

                <div className="ct_footer_info">
                  <i className="fa-solid fa-envelope"></i>

                  <a href="mailto:info@gmail.com">info@gmail.com</a>
                </div>
              </li>

              <li>
                <h6>Office Location</h6>

                <div className="ct_footer_info">
                  <i className="fa-solid fa-envelope"></i>

                  <a href="javascript:void(0)">
                    Xyz, 15 Sellamuttu Avenue, 03, UK
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-md-3 mb-4 col-sm-6">
          <div className="ct_footer_link">
            <h4>Contact Details</h4>

            <div className="ct_news_letter_div">
              <p>
                Sign Up to get updates & news about us . Get Latest Deals
                from Walker's Inbox to our mail address.
              </p>

              <div className="ct_news_leeter_btn">
                <input type="email" placeholder="Enter Your Email" />

                <button
                  className="ct_custom_btn w-100"
                  style={{borderRadius: "0px"}}
                >
                  Subscribe Now <i className="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="ct_subfooter mt-5">
      <p className="mb-0">
        © 2023 <a href="#">Cabty</a> . All Rights Reserved.
      </p>
    </div>
  </footer>
  )
}

export default Footer
