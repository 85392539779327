import { message, message as toast } from "antd";
import moment from "moment";

// Currency Symbol
export const curSym = "$";

// Authorizationw
export const pipSetAccessToken = (token) => {
  if (!token) return;
  localStorage.setItem("token", JSON.stringify(token));
};
export const pipGetAccessToken = () => {
  const token = localStorage.getItem("token");
  return token ? JSON.parse(token) : null;
};
export const clearAuth = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user_details");
  message.success("Successfully logged out");
  window.location.reload();
};

// Profile
export const pipSaveProfile = (profile) => {
  if (!profile) return;
  else localStorage.setItem("user_details", JSON.stringify(profile));
};

export const pipGetProfile = () => {
  const profile = localStorage.getItem("user_details");
  return profile ? JSON.parse(profile) : {};
};

// Date View Format
export const pipViewDate = (date) => {
  if (!date) return;
  return moment(date).format("DD-MM-YYYY");
};

// Message Handlers
export const pipSuccessMessage = (message) => {
  if (!message) return;
  return toast.success(message);
};

export const pipErrorMessage = (message) => {
  if (!message) return;
  return toast.error(message);
};

export const pipCardDateView = (date) => {
  if (!date) return;
  return moment(date, "YYYY-MM-DD").format("MM/YY");
};

// Ride time view
export const pipRideTimeView = (time) => {
  if (!time) return;
  return moment(time).format("ddd, MMM Do, YYYY hh:mm A");
};

export const getSubstring = (str, len, appendStr = "...") => {
  if (!str) return "";
  if (str?.length <= len) return str;
  return str?.substring(0, len) + appendStr;
};
