import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from "./Header";
import Footer from "./Footer";
import SimpleReactValidator from "simple-react-validator";
import CarLoader from "../Components/carLoader";
import axios from "axios";
import { baseUrl, supportAPI } from "../Components/Config";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import pageRoutes from "../routes";
import "./style.css";

const Home = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    comment: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [validator] = useState(
    new SimpleReactValidator({
      className: "text-danger",
    })
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      setIsLoading(true);
      axios({
        method: "post",
        url: baseUrl + supportAPI,
        data: formData,
      })
        .then((res) => {
          if (res?.data?.success) {
            message.success(res?.data?.message);
            setFormData({
              name: "",
              email: "",
              comment: "",
            });
            validator.hideMessages();
          } else {
            message.error(res?.data?.message);
          }
        })
        .catch((error) => {
          message.error("Something went wrong, please try again later");
          console.error("Error:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      validator.showMessages();
    }
  };

  const options = {
    loop: true,
    margin: 0,
    nav: true,
    center: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <>
      <Header />

      {/* Hero section start  */}

      {/* <section id="home">
        <OwlCarousel
          className="owl-carousel owl-theme ct_hero_slider"
          {...options}
        >
          <div className="item">
            <div
              className="ct_hero_slider_bnr"
              style={{ backgroundImage: `url(img/hero_bg_1.jpg)` }}
            >
              <div className="ct_hero_slider_bnr_cnt">
                <h2>Get Best Online</h2>

                <h1>BOOK YOUR RIDE</h1>

                <p>
                  Dramatically scale backward compatible portals after market
                  positioning deliverables. Assertively predominate
                  collaborative rather.
                </p>

                <div className="row mb-4">
                  <div className="col-md-6 mb-0 mb-md-0">
                    <div className="d-flex align-items-center gap-3 flex-wrap justify-content-center mb-3">
                      <h6 className="mb-0">Download User App</h6>
                      <div className="ct_dowload_apps mt-0">
                        <a
                          href="https://apps.apple.com/us/app/cabty/id6523432655"
                          target="_blank"
                        >
                          <img src="img/appstore.png" alt="" />
                        </a>

                        <a
                          href="https://play.google.com/store/apps/details?id=com.qr_cab&pcampaignid=web_share"
                          target="_blank"
                        >
                          <img src="img/google-play.png" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-4 mb-md-0">
                    <div className="d-flex align-items-center gap-3 flex-wrap justify-content-center mb-4">
                      <h6 className="mb-0">Download Driver App</h6>
                      <div className="ct_dowload_apps mt-0">
                        <a
                          href="https://apps.apple.com/us/app/cabty-driver/id6523432144"
                          target="_blank"
                        >
                          <img src="img/appstore.png" alt="" />
                        </a>

                        <a
                          href="https://play.google.com/store/apps/details?id=com.qr_cab_driver&pcampaignid=web_share"
                          target="_blank"
                        >
                          <img src="img/google-play.png" alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="item">
            <div
              className="ct_hero_slider_bnr"
              style={{ backgroundImage: "url(img/hero_bg_2.jpg)" }}
            >
              <div className="ct_hero_slider_bnr_cnt">
                <h2>Get Best Online</h2>

                <h1>BOOK YOUR RIDE</h1>

                <p>
                  Dramatically scale backward compatible portals after market
                  positioning deliverables. Assertively predominate
                  collaborative rather.
                </p>

                <div className="ct_dowload_apps">
                  <a href="#">
                    <img src="img/appstore.png" alt="" />
                  </a>

                  <a href="#">
                    <img src="img/google-play.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="item">
            <div
              className="ct_hero_slider_bnr"
              style={{ backgroundImage: "url(img/hero_bg_3.jpg)" }}
            >
              <div className="ct_hero_slider_bnr_cnt">
                <h2>Get Best Online</h2>

                <h1>BOOK YOUR RIDE</h1>

                <p>
                  Dramatically scale backward compatible portals after market
                  positioning deliverables. Assertively predominate
                  collaborative rather.
                </p>

                <div className="ct_dowload_apps">
                  <a href="#">
                    <img src="img/appstore.png" alt="" />
                  </a>

                  <a href="#">
                    <img src="img/google-play.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </section> */}

      <div className="cb_py_60" id="home">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-4 mb-lg-0">
              <div className="ct_hero_title">
                <h2 className="cb_fs_52 ct_fw_600 mb-3">
                  Go anywhere with Cabty
                </h2>
                <div className="cb_nav_tab">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="" role="presentation">
                      <button
                        class=" active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        <div className="cb_tab_icon">
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="m20.9 9-1.5-4.6c-.3-.8-1-1.4-1.9-1.4H6.4c-.9 0-1.6.5-1.9 1.4L3 9H1v3h1v9h4v-2h12v2h4v-9h1V9h-2.1ZM5 14h4v2H5v-2Zm10 2v-2h4v2h-4ZM7.1 6h9.7l1.3 4H5.8l1.3-4Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                        <span>Ride</span>
                      </button>
                    </li>
                    <li class="" role="presentation">
                      <button
                        class=""
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        <div className="cb_tab_icon">
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g fill="currentColor">
                              <path d="M21.9 6.4 12 1.5 8.1 3.4l9.9 5 3.9-2ZM2.1 6.4l3.8-1.9 9.9 5-3.8 1.9-9.9-5ZM11 23 1 18V8.1l10 5V23ZM19 13.8v-3.7l4-2V18l-10 5v-9.9l4-2v3.7l2-1Z"></path>
                            </g>
                          </svg>
                        </div>
                        <span>Package</span>
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <form className="cb_location_form mt-5">
                        <div className="cb_decoration_line">
                          <div className="position-relative mb-3">
                            <div className="cb_input_icon_2">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                data-baseweb="icon"
                              >
                                <title>search</title>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Zm0-8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                            <input
                              type="text"
                              name="Pickup"
                              placeholder="Pickup location"
                              className="form-control ct_input cb_text_indent_30 ct_input_grey"
                            />
                            {/* when user click on input then dropdown will show  */}
                            <div className="cb_location_dropdown d-none">
                              <ul>
                                <li>
                                  <div className="cb_city_icon">
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <title>Location marker</title>
                                      <path
                                        d="M18.7 3.8C15 .1 9 .1 5.3 3.8c-3.7 3.7-3.7 9.8 0 13.5L12 24l6.7-6.8c3.7-3.6 3.7-9.7 0-13.4ZM12 12.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="cb_location_name_title">
                                    <h5 className="ct_fs_18 ct_fw_600">
                                      Devi Ahilyabai Holkar International
                                      Airport, Indore
                                    </h5>
                                    <p className="mb-0">
                                      Indore, Madhya Pradesh
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="cb_city_icon">
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <title>Location marker</title>
                                      <path
                                        d="M18.7 3.8C15 .1 9 .1 5.3 3.8c-3.7 3.7-3.7 9.8 0 13.5L12 24l6.7-6.8c3.7-3.6 3.7-9.7 0-13.4ZM12 12.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="cb_location_name_title">
                                    <h5 className="ct_fs_18 ct_fw_600">
                                      Devi Ahilyabai Holkar International
                                      Airport, Indore
                                    </h5>
                                    <p className="mb-0">
                                      Indore, Madhya Pradesh
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="cb_city_icon">
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <title>Location marker</title>
                                      <path
                                        d="M18.7 3.8C15 .1 9 .1 5.3 3.8c-3.7 3.7-3.7 9.8 0 13.5L12 24l6.7-6.8c3.7-3.6 3.7-9.7 0-13.4ZM12 12.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="cb_location_name_title">
                                    <h5 className="ct_fs_18 ct_fw_600">
                                      Devi Ahilyabai Holkar International
                                      Airport, Indore
                                    </h5>
                                    <p className="mb-0">
                                      Indore, Madhya Pradesh
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="position-relative mb-3">
                            <div className="cb_input_icon_2">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                data-baseweb="icon"
                              >
                                <title>search</title>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M22 2H2v20h20V2Zm-7 7H9v6h6V9Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                            <input
                              type="text"
                              name="Pickup"
                              placeholder="Dropoff location"
                              className="form-control ct_input cb_text_indent_30 ct_input_grey"
                            />
                            {/* when user click on input then dropdown will show  */}
                            <div className="cb_location_dropdown d-none">
                              <ul>
                                <li>
                                  <div className="cb_city_icon">
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <title>Location marker</title>
                                      <path
                                        d="M18.7 3.8C15 .1 9 .1 5.3 3.8c-3.7 3.7-3.7 9.8 0 13.5L12 24l6.7-6.8c3.7-3.6 3.7-9.7 0-13.4ZM12 12.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="cb_location_name_title">
                                    <h5 className="ct_fs_18 ct_fw_600">
                                      Devi Ahilyabai Holkar International
                                      Airport, Indore
                                    </h5>
                                    <p className="mb-0">
                                      Indore, Madhya Pradesh
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="cb_city_icon">
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <title>Location marker</title>
                                      <path
                                        d="M18.7 3.8C15 .1 9 .1 5.3 3.8c-3.7 3.7-3.7 9.8 0 13.5L12 24l6.7-6.8c3.7-3.6 3.7-9.7 0-13.4ZM12 12.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="cb_location_name_title">
                                    <h5 className="ct_fs_18 ct_fw_600">
                                      Devi Ahilyabai Holkar International
                                      Airport, Indore
                                    </h5>
                                    <p className="mb-0">
                                      Indore, Madhya Pradesh
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="cb_city_icon">
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <title>Location marker</title>
                                      <path
                                        d="M18.7 3.8C15 .1 9 .1 5.3 3.8c-3.7 3.7-3.7 9.8 0 13.5L12 24l6.7-6.8c3.7-3.6 3.7-9.7 0-13.4ZM12 12.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="cb_location_name_title">
                                    <h5 className="ct_fs_18 ct_fw_600">
                                      Devi Ahilyabai Holkar International
                                      Airport, Indore
                                    </h5>
                                    <p className="mb-0">
                                      Indore, Madhya Pradesh
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="position-relative  mb-3">
                              <div className="cb_input_icon_2">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <title>Calendar</title>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M23 8V4h-3V1h-3v3H7V1H4v3H1v4h22Zm0 15H1V10h22v13ZM8 14H5v3h3v-3Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </div>
                              <input
                                type="date"
                                name="date"
                                className="form-control ct_input cb_text_indent_30 ct_input_grey"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="position-relative  mb-3">
                              <div className="cb_input_icon_2">
                                <svg
                                  width="1em"
                                  height="1em"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <title>Clock</title>
                                  <path
                                    d="M12 1C5.9 1 1 5.9 1 12s4.9 11 11 11 11-4.9 11-11S18.1 1 12 1Zm6 13h-8V4h3v7h5v3Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </div>
                              <select className="form-control ct_input cb_text_indent_30 ct_input_grey">
                                <option>5:30 PM</option>
                                <option>5:45 PM</option>
                                <option>5:60 PM</option>
                                <option>6:15 PM</option>
                                <option>6:30 PM</option>
                                <option>6:45 PM</option>
                                <option>7:00 PM</option>
                                <option>7:15 PM</option>
                                <option>7:30 PM</option>
                                <option>7:45 PM</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="mt-3">
                          <button
                            className="ct_submit_btn ms-0"
                            onClick={() => navigate(pageRoutes?.login)}
                          >
                            See Prices
                          </button>
                        </div>
                      </form>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <form className="cb_location_form mt-5">
                        <div className="cb_decoration_line">
                          <div className="position-relative mb-3">
                            <div className="cb_input_icon_2">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                data-baseweb="icon"
                              >
                                <title>search</title>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Zm0-8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                            <input
                              type="text"
                              name="Pickup"
                              placeholder="Pickup location"
                              className="form-control ct_input cb_text_indent_30 ct_input_grey"
                            />
                            {/* when user click on input then dropdown will show  */}
                            <div className="cb_location_dropdown d-none">
                              <ul>
                                <li>
                                  <div className="cb_city_icon">
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <title>Location marker</title>
                                      <path
                                        d="M18.7 3.8C15 .1 9 .1 5.3 3.8c-3.7 3.7-3.7 9.8 0 13.5L12 24l6.7-6.8c3.7-3.6 3.7-9.7 0-13.4ZM12 12.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="cb_location_name_title">
                                    <h5 className="ct_fs_18 ct_fw_600">
                                      Devi Ahilyabai Holkar International
                                      Airport, Indore
                                    </h5>
                                    <p className="mb-0">
                                      Indore, Madhya Pradesh
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="cb_city_icon">
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <title>Location marker</title>
                                      <path
                                        d="M18.7 3.8C15 .1 9 .1 5.3 3.8c-3.7 3.7-3.7 9.8 0 13.5L12 24l6.7-6.8c3.7-3.6 3.7-9.7 0-13.4ZM12 12.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="cb_location_name_title">
                                    <h5 className="ct_fs_18 ct_fw_600">
                                      Devi Ahilyabai Holkar International
                                      Airport, Indore
                                    </h5>
                                    <p className="mb-0">
                                      Indore, Madhya Pradesh
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="cb_city_icon">
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <title>Location marker</title>
                                      <path
                                        d="M18.7 3.8C15 .1 9 .1 5.3 3.8c-3.7 3.7-3.7 9.8 0 13.5L12 24l6.7-6.8c3.7-3.6 3.7-9.7 0-13.4ZM12 12.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="cb_location_name_title">
                                    <h5 className="ct_fs_18 ct_fw_600">
                                      Devi Ahilyabai Holkar International
                                      Airport, Indore
                                    </h5>
                                    <p className="mb-0">
                                      Indore, Madhya Pradesh
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="position-relative mb-3">
                            <div className="cb_input_icon_2">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                data-baseweb="icon"
                              >
                                <title>search</title>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M22 2H2v20h20V2Zm-7 7H9v6h6V9Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                            <input
                              type="text"
                              name="Pickup"
                              placeholder="Delivery location"
                              className="form-control ct_input cb_text_indent_30 ct_input_grey"
                            />
                            {/* when user click on input then dropdown will show  */}
                            <div className="cb_location_dropdown d-none">
                              <ul>
                                <li>
                                  <div className="cb_city_icon">
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <title>Location marker</title>
                                      <path
                                        d="M18.7 3.8C15 .1 9 .1 5.3 3.8c-3.7 3.7-3.7 9.8 0 13.5L12 24l6.7-6.8c3.7-3.6 3.7-9.7 0-13.4ZM12 12.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="cb_location_name_title">
                                    <h5 className="ct_fs_18 ct_fw_600">
                                      Devi Ahilyabai Holkar International
                                      Airport, Indore
                                    </h5>
                                    <p className="mb-0">
                                      Indore, Madhya Pradesh
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="cb_city_icon">
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <title>Location marker</title>
                                      <path
                                        d="M18.7 3.8C15 .1 9 .1 5.3 3.8c-3.7 3.7-3.7 9.8 0 13.5L12 24l6.7-6.8c3.7-3.6 3.7-9.7 0-13.4ZM12 12.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="cb_location_name_title">
                                    <h5 className="ct_fs_18 ct_fw_600">
                                      Devi Ahilyabai Holkar International
                                      Airport, Indore
                                    </h5>
                                    <p className="mb-0">
                                      Indore, Madhya Pradesh
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="cb_city_icon">
                                    <svg
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <title>Location marker</title>
                                      <path
                                        d="M18.7 3.8C15 .1 9 .1 5.3 3.8c-3.7 3.7-3.7 9.8 0 13.5L12 24l6.7-6.8c3.7-3.6 3.7-9.7 0-13.4ZM12 12.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="cb_location_name_title">
                                    <h5 className="ct_fs_18 ct_fw_600">
                                      Devi Ahilyabai Holkar International
                                      Airport, Indore
                                    </h5>
                                    <p className="mb-0">
                                      Indore, Madhya Pradesh
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3">
                          <button
                            className="ct_submit_btn ms-0"
                            onClick={() => navigate(pageRoutes?.login)}
                          >
                            See Prices
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-4 mb-lg-0  offset-lg-1">
              <div>
                <iframe
                  className="cb_border_radius_10"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9581874.19203155!2d-15.000813138693676!3d54.10344657823548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x25a3b1142c791a9%3A0xc4f8a0433288257a!2sUnited%20Kingdom!5e0!3m2!1sen!2sin!4v1733227364831!5m2!1sen!2sin"
                  width="100%"
                  height="500"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* About section S */}

      {/* <section className="ct_sec_padd" id="about">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-md-6 mb-4 mb-md-0"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <div className="ct_cabty_about_img">
                <img src="img/about_7_1.jpg" alt="" />
              </div>
            </div>

            <div
              className="col-md-6 mb-4 mb-md-0"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div className="ct_about_cnt">
                <h1 className="ct_about_sub_head">ABOUT OUR COMPANY</h1>

                <h2 className="ct_h2_head">
                  Wherever You Need To Go We'll Take You There.
                </h2>

                <p className="ct_para_p">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Neque architecto unde delectus cupiditate nam atque quos error
                  perferendis nemo. Commodi cupiditate eos obcaecati omnis non
                  corporis esse reiciendis totam accusamus.
                </p>

                <p className="ct_para_p">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Neque architecto unde delectus cupiditate nam atque quos error
                  perferendis nemo. Commodi cupiditate eos obcaecati omnis non
                  corporis esse reiciendis totam accusamus.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="ct_sec_padd" id="about">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="ct_fs_35 ct_fw_600 mb-5">Suggestions</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="cb_suggestion_card_grey">
                <div>
                  <h5 className="ct_fs_16 mb-2">Ride</h5>
                  <p className="mb-2 ct_fs_14">
                    Go anywhere with Cabty. Request a ride, hop in, and go.
                  </p>
                  <a
                    href="javascript:void(0)"
                    onClick={() => navigate(pageRoutes?.login)}
                    className="cb_grey_btn mt-3 d-inline-block "
                  >
                    Details
                  </a>
                </div>
                <div className="cb_suggestion_img">
                  <img src="img/ride.png" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="cb_suggestion_card_grey">
                <div>
                  <h5 className="ct_fs_16 mb-2">Reserve</h5>
                  <p className="mb-2 ct_fs_14">
                    Reserve your ride in advance so you can relax on the day of
                    your trip.
                  </p>
                  <a
                    href="javascript:void(0)"
                    onClick={() => navigate(pageRoutes?.login)}
                    className="cb_grey_btn mt-3 d-inline-block "
                  >
                    Details
                  </a>
                </div>
                <div className="cb_suggestion_img">
                  <img src="img/reserve_clock.png" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="cb_suggestion_card_grey">
                <div>
                  <h5 className="ct_fs_16 mb-2">Package</h5>
                  <p className="mb-2 ct_fs_14">
                    Cabty Connect makes same-day delivery easier than ever.
                  </p>
                  <a
                    href="javascript:void(0)"
                    onClick={() => navigate(pageRoutes?.login)}
                    className="cb_grey_btn d-inline-block mt-3"
                  >
                    Details
                  </a>
                </div>
                <div className="cb_suggestion_img">
                  <img src="img/connect.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Our Service Section S */}
      <section id="service">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 mb-4 mb-md-0">
              <div className="cb_drive_img">
                <img src="img/driver_img_234.webp" />
              </div>
            </div>
            <div className="col-md-6 mb-4 mb-md-0">
              <div>
                <h1 className="cb_fs_52 ct_fw_600 mb-5">
                  Drive when you want, make what you need
                </h1>
                <p className="mb-0">
                  Make money on your schedule with deliveries or rides—or both.
                  You can use your own car or choose a rental through Uber.
                </p>
                <div className="d-flex align-items-center gap-3 flex-wrap mt-4">
                  <a
                    href="javascript:void(0)"
                    onClick={() => navigate(pageRoutes?.login)}
                    className="ct_submit_btn mx-0 d-flex align-items-center justify-content-center"
                  >
                    Get started
                  </a>
                  <a
                    href="javascript:void(0)"
                    onClick={() => navigate(pageRoutes?.login)}
                    className="cb_link_underline"
                  >
                    Already have an account? Sign in
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="ct_services_bg ct_sec_padd" id="service">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="ct_about_sub_head">OUR SERVICES</h1>

              <h2 className="ct_h2_head text-white">
                Best Taxi Services For You
              </h2>
            </div>
          </div>

          <div className="row mt-5 my_curser_pointer">
            <div
              onClick={() => navigate(pageRoutes?.safety)}
              className="col-md-3 mb-4 mb-md-0 "
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <figure className="ct_service_box">
                <div className="ct_service_icon">
                  <img src="img/feature_1-1.svg" alt="" />
                </div>

                <figcaption>
                  <a href="javascript:void(0)">
                    {" "}
                    <h4>100% Safety</h4>
                  </a>

                  <p>
                    We’ll always prioritize your safety. Our commitment to high
                    standards begins before your first ride. Our proactive
                    safety features are always active, and we provide real help
                    from real people, anytime, day or night.
                  </p>

                  <img src="img/feature_1-1.svg" alt="" />
                </figcaption>
              </figure>
            </div>

            <div
              className="col-md-3 mb-4 mb-md-0"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <figure className="ct_service_box">
                <div className="ct_service_icon">
                  <img src="img/feature_2-1.svg" alt="" />
                </div>

                <figcaption>
                  <a href="#">
                    {" "}
                    <h4>Quick Ride</h4>
                  </a>

                  <p>
                    Globally generate business channels without next-genera tion
                    results. Efficiently.
                  </p>

                  <img src="img/feature_2-1.svg" alt="" />
                </figcaption>
              </figure>
            </div>

            <div
              className="col-md-3 mb-4 mb-md-0"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <figure className="ct_service_box">
                <div className="ct_service_icon">
                  <img src="img/feature_3-1.svg" alt="" />
                </div>

                <figcaption>
                  <a href="#">
                    <h4>Fast Pickups</h4>
                  </a>

                  <p>
                    Globally generate business channels without next-genera tion
                    results. Efficiently.
                  </p>

                  <img src="img/feature_3-1.svg" alt="" />
                </figcaption>
              </figure>
            </div>

            <div
              className="col-md-3 mb-4 mb-md-0"
              data-aos="fade-up"
              data-aos-duration="2500"
            >
              <figure className="ct_service_box">
                <div className="ct_service_icon">
                  <img src="img/feature_4-1.svg" alt="" />
                </div>

                <figcaption>
                  <a href="#">
                    <h4>Lots of locations</h4>
                  </a>

                  <p>
                    Globally generate business channels without next-genera tion
                    results. Efficiently.
                  </p>

                  <img src="img/feature_4-1.svg" alt="" />
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section> */}
      <section className="ct_sec_padd pn-0" id="howtouse">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="ct_fs_35 ct_fw_600 mb-2">
                How to use the Cabty app
              </h1>
              <p className="mb-5">
                Our core service is developing technology that connects drivers
                and riders on demand. Here’s how the app works, step by step:
              </p>
            </div>
          </div>
          <div className="row ct_res_relative">
            <div className="col-md-4 pb-4">
              <div className="cb_step_img">
                <img src="img/desk2x.jpg" className="w-100" />
              </div>
            </div>
            <div className="col-md-7 pb-4 offset-md-1">
              <div className="cb_how_work_cnt_2">
                <h6 className="mb-3">Step 1 </h6>
                <h4 className="mb-3">A rider opens the app</h4>
                <p className="mb-0">
                  The rider enters their destination into the “Where to?” box;
                  reviews each ride option for vehicle size, price, and
                  estimated dropoff time; chooses the desired option; then
                  confirms the pickup.
                </p>
              </div>
            </div>
          </div>
          <div className="row ct_res_relative">
            <div className="col-md-4 pb-4">
              <div className="cb_step_img">
                <img src="img/desk2x.jpg" className="w-100" />
              </div>
            </div>
            <div className="col-md-7 pb-4 offset-md-1">
              <div className="cb_how_work_cnt_2">
                <h6 className="mb-3">Step 2 </h6>
                <h4 className="mb-3">The rider is matched with a driver</h4>
                <p className="mb-0">
                  A nearby driver sees and chooses to accept the rider’s ride
                  request. The rider is automatically notified when the driver’s
                  vehicle is about a minute away.
                </p>
              </div>
            </div>
          </div>
          <div className="row ct_res_relative">
            <div className="col-md-4 pb-4">
              <div className="cb_step_img">
                <img src="img/desk2x.jpg" className="w-100" />
              </div>
            </div>
            <div className="col-md-7 pb-4 offset-md-1">
              <div className="cb_how_work_cnt_2">
                <h6 className="mb-3">Step 3</h6>
                <h4 className="mb-3">The driver picks up the rider</h4>
                <p className="mb-0">
                  The driver and the rider verify each other’s names and the
                  destination. Then the driver starts the ride.
                </p>
              </div>
            </div>
          </div>
          <div className="row ct_res_relative">
            <div className="col-md-4 pb-4">
              <div className="cb_step_img">
                <img src="img/desk2x.jpg" className="w-100" />
              </div>
            </div>
            <div className="col-md-7 pb-4 offset-md-1">
              <div className="cb_how_work_cnt_2">
                <h6 className="mb-3">Step 4</h6>
                <h4 className="mb-3">
                  The driver takes the rider to the destination
                </h4>
                <p className="mb-0">
                  The app gives the driver the option to access turn-by-turn
                  directions.
                </p>
              </div>
            </div>
          </div>
          <div className="row ct_res_relative">
            <div className="col-md-4 pb-4">
              <div className="cb_step_img">
                <img src="img/desk2x.jpg" className="w-100" />
              </div>
            </div>
            <div className="col-md-7 pb-4 offset-md-1">
              <div className="cb_how_work_cnt_2">
                <h6 className="mb-3">Step 5</h6>
                <h4 className="mb-3">
                  The driver and rider leave ratings and reviews
                </h4>
                <p className="mb-0">
                  At the end of each trip, drivers and riders can rate each
                  other from 1 to 5 stars. Riders also have the option to give
                  the driver compliments and a tip directly in the app.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="ct_sec_padd"
        id="download"
        data-aos="fade-down"
        data-aos-duration="2000"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ct_app_download_bg">
                <div className="row align-items-center">
                  <div className="col-md-7 mb-4 mb-md-0">
                    {/* <h1 className="ct_about_sub_head">
                      DOWNLOAD CABTY APP NOW
                    </h1> */}

                    <h2 className="ct_h2_head">It’s easier in the apps</h2>

                    {/* <p className="ct_para_p">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Neque architecto unde delectus cupiditate nam atque quos
                      error perferendis nemo. Commodi cupiditate eos obcaecati
                      omnis non corporis esse reiciendis totam accusamus.
                    </p> */}

                    <div>
                      {/* <h5 className="mb-2">
                        Scan to Download the Cabty User App
                      </h5> */}
                      <div className="ct_dowload_apps justify-content-start mb-3 mt-3">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.qr_cab&pcampaignid=web_share"
                          target="_blank"
                          className="ct_download_from_qr"
                        >
                          <img src="scanner/android_user_qr.png" alt="" />

                          <div>
                            <h4>Download the Cabty app</h4>

                            <p className="mb-0">Scan to download</p>
                          </div>
                        </a>

                        <a
                          href="https://play.google.com/store/apps/details?id=com.qr_cab_driver&pcampaignid=web_share"
                          target="_blank"
                          className="ct_download_from_qr"
                        >
                          <img src="scanner/ios_user_qr.png" alt="" />

                          <div>
                            <h4 className="ct_fs_24 ">
                              Download the Driver app
                            </h4>

                            <p className="mb-0">Scan to download</p>
                          </div>
                        </a>
                      </div>
                    </div>
                    {/* <div>
                      <h5 className="mb-2">
                        Scan to Download the Cabty Driver App
                      </h5>
                      <div className="ct_dowload_apps justify-content-start mt-3">
                        <a href="#" className="ct_download_from_qr">
                          <img src="scanner/android_driver_qr.png" alt="" />

                          <div>
                            <h4>Android App</h4>

                            <p className="mb-0">Scan to download</p>
                          </div>
                        </a>

                        <a href="#" className="ct_download_from_qr">
                          <img src="scanner/ios_driver_qr.png" alt="" />

                          <div>
                            <h4>iOS App</h4>

                            <p className="mb-0">Scan to download</p>
                          </div>
                        </a>
                      </div>
                    </div> */}
                  </div>

                  <div className="col-md-5 mb-4 mb-md-0">
                    <div className="ct_app_store_12">
                      <img src="img/phone_1-1-1.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Us Section S */}

      {/* <section
        className="ct_sec_padd pt-0 pb-5"
        id="Contact"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <h1 className="ct_about_sub_head text-center mb-0">CONTACT US</h1>

              <h2 className="ct_h2_head  text-center">Get Connect To Us </h2>

              <div className="ct_contact_form mt-5">
                {isLoading ? (
                  <CarLoader />
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group mb-4">
                      <label className="ct_fw_600 mb-2">Name</label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="form-control ct_input"
                        placeholder="Enter Name"
                      />
                      {validator.message(
                        "name",
                        formData.name,
                        "required|alpha_space"
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <label className="ct_fw_600 mb-2">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control ct_input"
                        placeholder="Enter Email"
                      />
                      {validator.message(
                        "email",
                        formData.email,
                        "required|email"
                      )}
                    </div>
                    <div className="form-group mb-4">
                      <label className="ct_fw_600 mb-2">Comment</label>
                      <textarea
                        type="text"
                        name="comment"
                        value={formData.comment}
                        onChange={handleChange}
                        className="form-control ct_input h-auto"
                        rows="4"
                        placeholder="Leave a Comment..."
                      ></textarea>
                      {validator.message(
                        "comment",
                        formData.comment,
                        "required"
                      )}
                    </div>

                    <button className="ct_submit_btn" onClick={handleSubmit}>
                      Submit
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div className="ct_footer_car_track">
        <img src="img/taxi_4.png" alt="" />

        <div
          className="ct_footer_car_trackimg"
          style={{ backgroundImage: "url(img/border_1.png)" }}
        ></div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
