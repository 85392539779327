import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Setting from "./Setting";
import UserRide from "./Components/UserRide";
import Users from "./Components/Users";
import ChangePassword from "./Components/ChangePassword";
import Login from "./Components/Login";
import ForgetPassword from "./Components/ForgetPassword";
import Dashboard from "./Components/Dashboard";
import Cabs from "./Components/Cabs";
import BookRides from "./Components/web_user/BookRides";
import VerifyRide from "./Components/web_user/VerifyRide";
import UserProfile from "./Components/web_user/UserProfile";
import AllBooking from "./Components/web_user/AllBooking";
import Location from "./Components/Location";
import Rides from "./Components/Rides";
import Support from "./Support";
import Category from "./Components/Category";
import Home from "./home";
import Safety from "./home/Safety";
import pageRoutes from "./routes";
import ReAssign from "./Setting/ReAssign";
import Complaint from "./Setting/Complaint";
import EmergencyNotification from "./Setting/EmergencyNotification";
import SupportOther from "./Support/SupportOther";

function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route exact path={pageRoutes?.home} element={<Home />} />
          <Route exact path={pageRoutes?.safety} element={<Safety />} />
          <Route exact path={pageRoutes?.dashboard} element={<Dashboard />} />
          <Route exact path={pageRoutes?.login} element={<Login />}></Route>
          <Route exact path={pageRoutes?.rides} element={<Rides />}></Route>
          <Route
            exact
            path={pageRoutes?.settings}
            element={<Setting />}
          ></Route>
          <Route
            exact
            path={pageRoutes?.userRide}
            element={<UserRide />}
          ></Route>
          <Route exact path={pageRoutes?.users} element={<Users />}></Route>
          <Route
            exact
            path={pageRoutes?.changePassword}
            element={<ChangePassword />}
          ></Route>
          <Route
            exact
            path={pageRoutes?.forgetPassword}
            element={<ForgetPassword />}
          ></Route>
          <Route exact path={pageRoutes?.cabs} element={<Cabs />} />
          <Route exact path={pageRoutes?.bookRide} element={<BookRides />} />
          <Route
            exact
            path={pageRoutes?.otpVerification}
            element={<VerifyRide />}
          />
          <Route
            exact
            path={pageRoutes?.userProfile}
            element={<UserProfile />}
          />
          <Route exact path={pageRoutes?.allBooking} element={<AllBooking />} />
          <Route exact path={pageRoutes?.location} element={<Location />} />
          <Route exact path={pageRoutes?.support} element={<Support />} />
          <Route exact path={pageRoutes?.category} element={<Category />} />

          <Route exact path={pageRoutes?.reAssign} element={<ReAssign />} />
          <Route
            exact
            path={pageRoutes?.emergencyNotification}
            element={<EmergencyNotification />}
          />
          <Route exact path={pageRoutes?.complaint} element={<Complaint />} />
          <Route exact path={pageRoutes?.supportOther} element={<SupportOther />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
