import { createSlice } from "@reduxjs/toolkit";
import { fetchReassignRequest } from "../action/settingAction";

const initialState = {
  isLoading: false,
  reassignRequestList: [],
};

export const settingSlice = createSlice({
  name: "setting",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch-profile
    builder.addCase(fetchReassignRequest.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchReassignRequest.fulfilled, (state, action) => {
      const { data, success } = action?.payload ?? {};
      state.reassignRequestList = data ?? [];
      state.isLoading = false;
    });
    builder.addCase(fetchReassignRequest.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export default settingSlice.reducer;
